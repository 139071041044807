<template>
  <div v-if="fullscreen" class="fullscreen-overlay">
    <div class="fullscreen-content">
      <div class="flex w-full gap-x-8">
        <div class="flex w-full">
          <div class="main-border bg-light rounded-lg w-full flex justify-center items-center p-12" style="height: 100%">
            <div class="relative w-full flex justify-center">
              <div class="angleLine angle1" style="top: 0; left: 0">&nbsp;</div>
              <div class="angleLine angle2" style="bottom: 0; left: 0">&nbsp;</div>
              <div class="angleLine angle3" style="top: 0; right: 0">&nbsp;</div>
              <div class="angleLine angle4" style="bottom: 0; right: 0">&nbsp;</div>
              <div
                :class="{ 'border-success': finished }"
                class="main-border border-primary rounded-full flex justify-center"
                style="width: fit-content"
              >
                <svg :width="size" :height="size" class="countdown-circle">
                  <circle :r="radius" cx="50%" cy="50%" :stroke="strokeColor" :stroke-width="strokeWidth" fill="transparent"></circle>
                  <circle
                    :r="radius"
                    cx="50%"
                    cy="50%"
                    :stroke="progressColor"
                    :stroke-width="strokeWidth"
                    fill="transparent"
                    :stroke-dasharray="dashArray"
                    :stroke-dashoffset="dashOffset"
                  ></circle>
                  <text
                    v-if="classType === 'Other'"
                    x="50%"
                    y="26%"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    font-size="20"
                    fill="#fff"
                    transform="rotate(90, 300, 300)"
                  >
                    <tspan x="50%" dy="1.2em" v-for="(line, index) in splitText(timer.title)" :key="index">{{ line }}</tspan>
                  </text>
                  <text
                    v-else
                    x="50%"
                    y="30%"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    font-size="24"
                    fill="#fff"
                    transform="rotate(90, 300, 300)"
                  >
                    Class {{ classType }} Timer
                  </text>
                  <text
                    x="50%"
                    y="52%"
                    text-anchor="middle"
                    alignment-baseline="middle"
                    font-size="100"
                    fill="#fff"
                    transform="rotate(90, 300, 300)"
                  >
                    {{ formattedTime }}
                  </text>
                  <a @click="toggleFullscreen()" style="cursor: pointer">
                    <circle r="30" cx="25%" cy="50%" stroke="#fff" stroke-width="3" fill="transparent"></circle>
                    <image :xlink:href="exitFullscreenIcon" width="30" height="30" y="47.5%" x="22.5%" />
                  </a>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="flex w-full gap-x-8">
    <div class="flex w-full">
      <div class="main-border rounded-lg w-full flex justify-center items-center p-12" style="height: 100%">
        <div class="relative w-full flex justify-center">
          <div class="angleLine angle1" style="top: 0; left: 0">&nbsp;</div>
          <div class="angleLine angle2" style="bottom: 0; left: 0">&nbsp;</div>
          <div class="angleLine angle3" style="top: 0; right: 0">&nbsp;</div>
          <div class="angleLine angle4" style="bottom: 0; right: 0">&nbsp;</div>
          <div
            :class="{ 'border-success': finished }"
            class="rounded-full border-primary flex justify-center main-border"
            style="width: fit-content"
          >
            <svg :width="size" :height="size" class="countdown-circle">
              <circle :r="radius" cx="50%" cy="50%" :stroke="strokeColor" :stroke-width="strokeWidth" fill="transparent"></circle>
              <circle
                :r="radius"
                cx="50%"
                cy="50%"
                :stroke="progressColor"
                :stroke-width="strokeWidth"
                fill="transparent"
                :stroke-dasharray="dashArray"
                :stroke-dashoffset="dashOffset"
              ></circle>
              <text
                v-if="classType === 'Other'"
                x="50%"
                y="26%"
                text-anchor="middle"
                alignment-baseline="middle"
                font-size="20"
                fill="#fff"
                transform="rotate(90, 300, 300)"
              >
                <tspan x="50%" dy="1.2em" v-for="(line, index) in splitText(timer.title)" :key="index">{{ line }}</tspan>
              </text>
              <text
                v-else
                x="50%"
                y="30%"
                text-anchor="middle"
                alignment-baseline="middle"
                font-size="24"
                fill="#fff"
                transform="rotate(90, 300, 300)"
              >
                Class {{ classType }} Timer
              </text>
              <text
                x="50%"
                y="52%"
                text-anchor="middle"
                alignment-baseline="middle"
                font-size="100"
                fill="#fff"
                transform="rotate(90, 300, 300)"
              >
                {{ formattedTime }}
              </text>
              <a @click="toggleFullscreen()" style="cursor: pointer">
                <circle r="30" cx="25%" cy="50%" stroke="#fff" stroke-width="3" fill="transparent"></circle>
                <image :xlink:href="fullscreenIcon" width="25" height="25" y="47.9%" x="22.8%" />
              </a>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classType: null,
      timer: null,
      finished: false,

      fullscreen: false,

      size: 600,
      radius: 240,
      strokeWidth: 30,
      strokeColor: '#181d2a',
      progressColor: '#0053ff',
      textColor: '#000',
      fontSize: '60px',
      totalTime: null,
      currentTime: null,
      timerInterval: null,

      fullscreenIcon: 'https://connect-cdn.intellectualpoint.com/assets/images/icons/instructor-tools/FullScreenIcon.png',
      exitFullscreenIcon: 'https://connect-cdn.intellectualpoint.com/assets/images/icons/instructor-tools/ExitFullScreenIcon.png',
    };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.currentTime);
      const remainingSeconds = Math.floor((this.currentTime * 60) % 60);

      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    },
    dashArray() {
      return 2 * Math.PI * this.radius;
    },
    dashOffset: {
      get() {
        const progressPercentage = (this.currentTime / this.totalTime) * 100;
        return ((100 - progressPercentage) / 100) * this.dashArray;
      },
      set(value) {
        // Do nothing, as dashOffset is a derived property
      },
    },
  },
  methods: {
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.currentTime -= 1 / 60;
        this.updateTimer();
        if (this.currentTime <= 0) {
          this.finished = true;
          this.currentTime = 0;
          clearInterval(this.timerInterval);

          this.$confetti.start({});
          this.simulationFullCorrectSound.play();

          setTimeout(() => {
            this.$confetti.stop();
          }, 3000);
        }
      }, 1000);
    },

    updateTimer() {
      const progressPercentage = (this.currentTime / this.totalTime) * 100;
      this.dashOffset = ((100 - progressPercentage) / 100) * this.dashArray;
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    formatTimerTitle(title) {
      const regex = new RegExp('.{1,30}', 'g');
      return title.match(regex) || [];
    },
    splitText(text) {
      const maxCharactersPerLine = 30;
      const words = text.split(/\s+/);
      const lines = [];
      let currentLine = '';

      for (const word of words) {
        if (`${currentLine} ${word}`.length <= maxCharactersPerLine) {
          // Add word to the current line
          currentLine += (currentLine.length === 0 ? '' : ' ') + word;
        } else {
          // Start a new line
          lines.push(currentLine);
          currentLine = word;
        }
      }

      // Add the last line
      if (currentLine.length > 0) {
        lines.push(currentLine);
      }

      return lines;
    },
  },
  mounted() {
    this.timer = this.$route.query.timer;
    this.classType = this.$route.query.classType;
    this.currentTime = this.timer.length;
    this.totalTime = this.timer.length;
    this.startTimer();
    this.simulationFullCorrectSound = new Audio('https://connect-cdn.intellectualpoint.com/assets/other/sounds/timer-finished.wav');
    window.simulationFullCorrectSound = this.simulationFullCorrectSound;
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
};
</script>

<style lang="scss">
.countdown-circle {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.angleLine {
  width: 50px;
  height: 50px;
  position: absolute;
}
.angle1 {
  border-top: 1px solid #3e5271;
  border-left: 1px solid #3e5271;
}
.angle2 {
  border-bottom: 1px solid #3e5271;
  border-left: 1px solid #3e5271;
}
.angle3 {
  border-top: 1px solid #3e5271;
  border-right: 1px solid #3e5271;
}
.angle4 {
  border-bottom: 1px solid #3e5271;
  border-right: 1px solid #3e5271;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 52000;
  backdrop-filter: blur(8px);
}

.fullscreen-content {
  width: 80%;
  height: 80%;
  padding: 20px;
  border-radius: 10px;
  overflow: auto;
  z-index: 52000;
}
</style>
